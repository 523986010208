<template>
  <div
    id="view-chapters"
    class="view"
    :class="{ 'items-shown': !slideCurrentlyChanging }"
  >
    <keep-alive>
      <transition
        v-if="viewLoaded"
        name="slideChange"
        :css="false"
        v-on:before-enter="beforeEnter"
        v-on:enter="enter"
        v-on:after-enter="afterEnter"
        v-on:before-leave="beforeLeave"
        v-on:leave="leave"
        v-on:after-leave="afterLeave"
      >
        <router-view
          v-if="currentSlide"
          :key="currentSlide"
          :slide="currentSlideData"
          :transitionSlideEnter="slideEnter"
          :transitionSlideLeave="slideLeave"
          ref="activeSlide"
          @changeslide="changeSlide"
        ></router-view>
      </transition>
    </keep-alive>
    <svg class="defs-only">
      <filter id="filter-chapter-slider">
        <feColorMatrix
          type="matrix"
          values=".33 .33 .33 0 0
              .33 .33 .33 0 0
              .33 .33 .33 0 0
              0 0 0 1 0"
        ></feColorMatrix>

        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" :tableValues="colorFilters.start.R"></feFuncR>
          <feFuncG type="table" :tableValues="colorFilters.start.G"></feFuncG>
          <feFuncB type="table" :tableValues="colorFilters.start.B"></feFuncB>
        </feComponentTransfer>
      </filter>
    </svg>
  </div>
</template>

<script>
import { loadImages, storeDataLoading, timeNow } from "@/mixins/core.js";
import {
  colorFilterValues,
  colorFilterTimeline,
  sliderInput,
} from "@/mixins/chapters/slider.js";

export default {
  name: "ViewChapters",
  mixins: [
    loadImages,
    storeDataLoading,
    timeNow,
    colorFilterValues,
    colorFilterTimeline,
    sliderInput,
  ],
  components: {},
  props: {},
  data() {
    return {
      slides: this.$store.state.data.OUTLINE.data.chapters,
      currentSlide: null,
      changeEnterDuration: 1300,
      changeLeaveDuration: 0,
      slideEnter: null,
      slideLeave: null,
      slideCurrentlyChanging: false,
      viewEnterDelay: 0,
      viewLeaveDuration: 400,
    };
  },
  computed: {
    changeDuration() {
      return this.changeEnterDuration + this.changeLeaveDuration;
    },
    slidesIndex() {
      return this.slides.map((slide) => slide.id);
    },
    currentSlideIndex() {
      return this.slidesIndex.indexOf(this.currentSlide);
    },
    currentSlideData() {
      const slideData = this.slides[this.currentSlideIndex];
      this.setSlidesIndexToCurrent();

      //pass next & prev slides
      const directions = ["next", "prev"];
      directions.forEach((direction) => {
        let adjacentIndex = this.getAdjacentSlideIndex(direction);

        this.slides[this.currentSlideIndex][direction] = {
          title: `${this.slides[adjacentIndex].titlePrefix} ${this.slides[adjacentIndex].title}`,
          href: this.slides[adjacentIndex].id,
        };
      });

      return slideData;
    },
    imagesToLoad() {
      const images = [];
      this.slides.map((slide) => {
        images.push(slide.images.main);
        images.push(slide.images.background);
      });
      return images;
    },
  },
  watch: {
    currentSlide() {
      if (this.currentSlide && !this.slidesIndex.includes(this.currentSlide)) {
        return this.$router.push("/chapters");
      }
    },
    $route(to, from) {
      this.currentSlide = to.params.slide;
      //run the svg filter timeline
      if (
        to.name === "view-chapter-slide" &&
        from.name === "view-chapter-slide"
      ) {
        this.createColorFilterTimeline();
      } else {
        this.killColorFilterTimeline();
      }
    },
  },
  created() {
    this.preloadImages(this.imagesToLoad, this.initView);
  },
  beforeRouteUpdate(to, from, next) {
    const vm = this;

    //dont navigate if going from a chapter slide to chapters home
    if (
      to.redirectedFrom === "/chapters/" &&
      to.name === "view-chapter-slide"
    ) {
      console.log("dont navigate as going to chapters home");
    } else {
      console.log("slideCurrentlyChanging is " + vm.slideCurrentlyChanging);
      if (!vm.slideCurrentlyChanging) {
        next();
      }
    }
  },
  //clean up stage before leave
  beforeRouteLeave(to, from, next) {
    const vm = this;
    const el = this.$refs.activeSlide.$el;

    this.slideLeave = el;
    this.leaveView(to, from, next);
  },
  methods: {
    //init slides
    viewShow() {
      this.currentSlide = this.$route.params.slide;
    },
    setSlidesIndexToCurrent() {
      this.slides[this.currentSlideIndex].i = this.currentSlideIndex;
    },
    //advance the slide
    getAdjacentSlideIndex(direction) {
      let adjacentSlideIndex = this.currentSlideIndex;

      if (direction === "next") {
        adjacentSlideIndex++;
        if (adjacentSlideIndex >= this.slidesIndex.length) {
          adjacentSlideIndex = 0;
        }
      } else {
        adjacentSlideIndex--;
        if (adjacentSlideIndex < 0) {
          adjacentSlideIndex = this.slidesIndex.length - 1;
        }
      }

      return adjacentSlideIndex;
    },
    slideNext() {
      if (this.slideCurrentlyChanging) return false;

      let next = this.getAdjacentSlideIndex("next");
      this.$router.push({
        name: "view-chapter-slide",
        params: {
          slide: this.slidesIndex[next],
        },
      });
    },
    //retract the slide
    slidePrev() {
      if (this.slideCurrentlyChanging) return false;

      let prev = this.getAdjacentSlideIndex("prev");
      this.$router.push({
        name: "view-chapter-slide",
        params: {
          slide: this.slidesIndex[prev],
        },
      });
    },
    changeSlide(direction) {
      direction === "next" ? this.slideNext() : this.slidePrev();
    },
    //slide transition
    beforeEnter: function (el) {
      //set entering slide to pass as prop
      this.slideEnter = el;
      //this.createColorFilterTimeline();
    },
    enter: function (el, done) {
      this.slideCurrentlyChanging = true;

      //duration of slidechange
      setTimeout(function () {
        done();
      }, this.changeDuration);
    },
    afterEnter: function (el) {
      this.slideCurrentlyChanging = false;
    },
    //leaving
    beforeLeave: function (el) {
      //set the leaving slide el
      this.slideLeave = el;
    },
    leave: function (el, done) {
      //duration of slidechange
      setTimeout(function () {
        done();
      }, this.changeDuration);
    },
    afterLeave: function (el) {},
  },
};
</script>

<style scoped></style>
